import React from "react";
import { graphql, Link } from "gatsby";
import Container from "../components/container/container";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import BlockContent from "../components/block-content";
import * as styles from "./onboarding.module.scss";

export interface OnboardingPageModel {
  title: string;
  _rawDescription: any;
  _rawAutomaticSummary: any;
  _rawManualSummary: any;
}

export const query = graphql`
  query OnboardingPageQuery {
    site: sanityOnboardingPage {
      _rawDescription
      _rawAutomaticSummary
      _rawManualSummary
      title
    }
  }
`;

const OnboardingPage = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site: OnboardingPageModel = (data || {}).site;

  return (
    <Layout>
      <SEO title={site.title} />
      <Container role="main">
        <div>
          <h1>{site.title}</h1>
          <div className={styles.boxContainer}>
            <div className={styles.boxStyle}>
              <BlockContent blocks={site._rawAutomaticSummary} />
              <div className={styles.buttonContainer}>
                <Link to="/onboarding-automatic" className="primary-link-btn">
                  More info
                </Link>
              </div>
            </div>
            <div className={styles.boxStyle}>
              <BlockContent blocks={site._rawManualSummary} />
              <div className={styles.buttonContainer}>
                <Link to="/onboarding-manual" className="primary-link-btn">
                  More info
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default OnboardingPage;
